<script setup lang="ts">
import { computed } from 'vue'
import { useUserStore } from '/@src/stores/userStore'

const userStore = useUserStore()

const isPopupVisible = computed(() => userStore.isOnboardingPopupVisible)
const steps = computed(() => userStore.onboardingSteps)
const completedSteps = computed(() => userStore.completedSteps)
const progress = computed(() => userStore.onboardingProgress)

const togglePopup = () => {
  userStore.toggleOnboardingPopup()
}
</script>

<template>
  <div
    v-if="progress && progress < 100"
    class="onboarding-wrapper"
  >
    <!-- Toggle button always visible -->
    <Button
      v-if="!isPopupVisible"
      rounded
      icon-left="ph:list-checks"
      @click="togglePopup"
    >
      <span>{{ completedSteps }}/{{ steps.length }}</span>
    </Button>

    <button
      v-if="isPopupVisible"
      class="side-menu-background"
      @click="togglePopup"
    />
    <!-- Onboarding panel -->
    <Transition name="slide">
      <div
        v-if="isPopupVisible"
        class="onboarding-progress"
      >
        <div class="progress-header">
          <div class="is-flex is-justify-content-space-between is-align-items-center">
            <Title
              :size="6"
              weight="semi"
              class="progress-title"
            >
              Onboarding Progress
            </Title>
            <Button
              v-if="isPopupVisible"
              rounded
              inner
              icon-left="ph:x"
              @click="togglePopup"
            />
          </div>
          <p class="progress-subtitle">
            {{ completedSteps }}/{{ steps.length }} steps completed
          </p>

          <progress
            class="progress is-primary is-tiny"
            :value="progress"
            max="100"
          >
            {{ progress }}%
          </progress>
        </div>

        <ul class="steps-list">
          <li
            v-for="step in steps"
            :key="step.id"
            :class="{ 'is-completed': step.isCompleted(userStore.user!) }"
          >
            <RouterLink
              v-if="step.link"
              :to="step.link"
              class="step-link"
            >
              <i
                class="iconify"
                :data-icon="step.icon"
              />
              <span>{{ step.label }}</span>
              <i
                v-if="step.isCompleted(userStore.user!)"
                class="iconify check-icon"
                data-icon="ph:check-circle-fill"
              />
            </RouterLink>
            <div
              v-else
              class="step-link"
            >
              <i
                class="iconify"
                :data-icon="step.icon"
              />
              <span>{{ step.label }}</span>
              <i
                v-if="step.isCompleted(userStore.user!)"
                class="iconify check-icon"
                data-icon="ph:check-circle-fill"
              />
            </div>
          </li>
        </ul>
      </div>
    </Transition>
  </div>
</template>

<style lang="scss" scoped>
.onboarding-wrapper {
  position: relative;
  display: inline-flex;
}

.side-menu-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  backdrop-filter: blur(5px);
  background-color: transparent;
  border: none;
}


.toggle-button {
  z-index: 1000;
}

.onboarding-progress {
  position: fixed; // Change from absolute to fixed
  right: 1rem;    // Add some spacing from the right edge
  top: 1rem;      // Add some spacing from the top
  width: 350px;
  background: var(--card-bg-color);
  border-radius: 0.75rem;
  border: 1px solid var(--card-border-color);
  padding: 1.5rem;
  z-index: 1000;
  max-height: calc(100vh - 2rem); // Prevent overflow
  overflow-y: auto; // Make it scrollable if content is too long

  // Add media query for mobile
  @media screen and (max-width: 768px) {
    width: calc(100% - 2rem); // Full width minus margins
    right: 1rem;
    left: 1rem;
    top: auto;    // Remove top positioning
    bottom: 1rem; // Position from bottom instead
    max-height: 80vh; // Limit height on mobile
  }
}


.progress-header {
  margin-bottom: 1.5rem;

  .progress-title {
    margin-bottom: 0;
  }

  .progress-subtitle {
    color: var(--light-text);
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }

  .progress {
    height: 0.5rem;
    &::-webkit-progress-value {
      transition: width 0.3s ease;
    }
  }
}

.steps-list {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 60vh; // Limit height
  overflow-y: auto; // Make scrollable

  @media screen and (max-width: 768px) {
    max-height: 50vh; // Slightly smaller on mobile
  }

  li {
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    &.is-completed {
      .step-link {
        color: var(--light-text);
        background: var(--card-bg-color);

        .check-icon {
          opacity: 1;
          color: var(--success);
        }
      }
    }
  }
}

.step-link {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  border-radius: 6px;
  color: var(--primary);
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    background: var(--card-bg-color);
    color: var(--primary);
  }

  .iconify {
    margin-right: 0.75rem;
    font-size: 1.2rem;
  }

  .check-icon {
    margin-left: auto;
    margin-right: 0;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  span {
    font-size: 0.95rem;
  }
}

/// Animation
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
</style>
