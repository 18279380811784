// src/stores/userStore.ts
import { defineStore } from 'pinia'
import axios from 'axios'
import { type User } from '/@src/types'
import { useStore } from '../composables/useStore'

interface SubscriptionStatus {
  isActive: boolean;
  expiresAt?: string;
}

interface OnboardingStep {
  id: string;
  label: string;
  icon: string;
  link?: string;
  isCompleted: (state: User | any) => boolean;
}

interface UserStats {
  assistants_count: number;
  stores_count: number;
  interviews_count: number;
  new_interviews_count: number;
  payment_status: {
    has_customer_id: boolean;
    has_payment_method: boolean;
    stripe_connect_status: string;
  };
}

interface UserState {
  user: User | null;
  isLoading: boolean;
  subscriptionStatus: SubscriptionStatus | null;
  lastSubscriptionCheck: number;
  subscriptionCheckPromise: Promise<boolean> | null;
  isOnboardingVisible: boolean;
  isOnboardingPopupVisible: boolean;
  stores: any[];
  userStats: UserStats | null;
}

export const useUserStore = defineStore('user', {
  state: (): UserState => {
    return {
      user: null,
      isLoading: false,
      subscriptionStatus: null,
      lastSubscriptionCheck: 0,
      subscriptionCheckPromise: null,
      isOnboardingVisible: true,
      isOnboardingPopupVisible: localStorage.getItem('onboardingPopupClosed') !== 'true',
      stores: [],
      userStats: null,
    }
  },

  getters: {
    isAuthenticated: (state): boolean => !!state.user,
    isSigned: (state): boolean => !!state.user && state.user.signed,
    isSubscribed: (state): boolean => !!state.subscriptionStatus?.isActive,


    onboardingSteps(): OnboardingStep[] {
      return [
        {
          id: 'trial',
          label: 'Start free trial',
          icon: 'ph:flag-banner',
          link: '/app/subscription',
          isCompleted: (user) => !!user?.stripeCustomerId,
        },
        {
          id: 'payment',
          label: 'Connect payment method',
          icon: 'ph:credit-card',
          link: '/app/account#subscription',
          isCompleted: (user) => !!user?.signed,
        },
        {
          id: 'stripe',
          label: 'Enable monetization',
          icon: 'ph:stripe-logo',
          link: '/app/account#monetization',
          isCompleted: (user) => user?.stripe_connect_status === 'active',
        },
        {
          id: 'agents',
          label: 'Create 2 agents',
          icon: 'ph:robot',
          link: '/app/create',
          isCompleted: () => (this.userStats?.assistants_count ?? 0) >= 2,
        },
        {
          id: 'agency',
          label: 'Start an agency',
          icon: 'ph:buildings',
          link: '/app#agencies',
          isCompleted: () => (this.userStats?.stores_count ?? 0) > 0,
        },
        {
          id: 'interviews',
          label: 'Get first reports',
          icon: 'ph:chat-circle',
          link: '/app#reports',
          isCompleted: () => (this.userStats?.interviews_count ?? 0) > 0,
        }
      ]
    },

    completedSteps(state): number {
      if (!state.user) return 0;
      return this.onboardingSteps.filter(step => step.isCompleted(state.user!)).length;
    },

    onboardingProgress(): number {
      return (this.completedSteps / this.onboardingSteps.length) * 100;
    },
  },

  actions: {
    initializeClientState() {
      if (typeof window !== 'undefined') {
          this.isOnboardingVisible = localStorage.getItem('onboardingVisible') !== 'false'
      }
    },
    async setUser(userData: User, stats?: UserStats) {
      this.user = userData
      this.userStats = stats || null
      await this.checkOnboardingStatus()
    },

    // Inside userStore.ts
    async updateStores() {
      // Add explicit authentication check
      if (!this.isAuthenticated || !this.user) {
        console.warn('Attempted to update stores without authentication')
        return
      }

      const { fetchStores } = useStore()
      try {
        const stores = await fetchStores()
        this.stores = stores || []
      } catch (error) {
        console.error('Failed to update stores:', error)
      }
    },


    setSubscriptionStatus(status: SubscriptionStatus) {
      this.subscriptionStatus = status;
    },

    async checkOnboardingStatus() {
      if (!this.isAuthenticated) return

      await this.updateStores()

      const storedVisibility = localStorage.getItem('onboardingVisible')

      // If user has explicitly closed it before, don't show it again
      if (storedVisibility === 'false') {
        this.isOnboardingVisible = false
        return
      }

      // Hide if all steps completed
      if (this.completedSteps === this.onboardingSteps.length) {
        this.isOnboardingVisible = false
        localStorage.setItem('onboardingVisible', 'false')
        return
      }

      // Show if not completed and not explicitly closed
      if (!this.isOnboardingVisible && this.completedSteps < this.onboardingSteps.length) {
        this.isOnboardingVisible = true
        localStorage.setItem('onboardingVisible', 'true')
      }
    },

    toggleOnboardingPopup() {
      this.isOnboardingPopupVisible = !this.isOnboardingPopupVisible;
      localStorage.setItem('onboardingPopupClosed', (!this.isOnboardingPopupVisible).toString());
    },

    clearUser() {
      this.user = null;
      this.userStats = null;
      this.subscriptionStatus = null;
      this.lastSubscriptionCheck = 0;
      this.subscriptionCheckPromise = null;
    },

    async checkSubscription(token: string) {
      const now = Date.now();
      const oneHour = 60 * 60 * 1000;

      if (this.subscriptionCheckPromise) {
        return this.subscriptionCheckPromise;
      }
      if (now - this.lastSubscriptionCheck < oneHour && this.subscriptionStatus !== null) {
        return this.isSubscribed;
      }
      this.isLoading = true;
      this.subscriptionCheckPromise = new Promise(async (resolve) => {
        try {
          const response = await axios.get('/check-subscription', {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          this.setSubscriptionStatus({
            isActive: response.data.is_active,
            expiresAt: response.data.expires_at,
          });
          this.lastSubscriptionCheck = now;
          resolve(this.isSubscribed);
        } catch (error) {
          console.error('Subscription check failed:', error);
          resolve(this.isSubscribed);
        } finally {
          this.isLoading = false;
          this.subscriptionCheckPromise = null;
        }
      });

      return this.subscriptionCheckPromise;
    },
  },
})
