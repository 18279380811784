// src/composables/useSessionInitializer.ts

import { ref, type Ref } from 'vue'
import axios, { type AxiosResponse } from 'axios'
import useAuth from '/@src/composables/useAuth'

interface SessionResponse {
  thread_id: string
  jwt_token: string
}

export function useSessionInitializer() {
  const {
    isAuthenticated,
    getAccessTokenSilently,
    user,
    signup,
  } = useAuth()
  const backendUrl: string = import.meta.env.VITE_BACKEND_URL
  const isLoading: Ref<boolean> = ref(false)
  const errorMessage: Ref<string | null> = ref(null)

function isValidThreadId(threadId: string | undefined): boolean {
  return Boolean(threadId && threadId !== 'thread_id' && threadId.length > 0)
}

function saveSession(
  chatId: string,
  name: string | null,
  email: string | null,
  thread_id: string,
  jwt_token: string,
): boolean {
  if (!isValidThreadId(thread_id)) {
    console.warn('Attempted to save session with invalid thread_id:', thread_id)
    return false
  }
  const key = `${chatId}_${name}_${email}`
  localStorage.setItem(key, JSON.stringify({ thread_id, jwt_token }))
  return true
}

function loadSession(
  chatId: string,
  name: string | null,
  email: string | null,
): SessionResponse | null {
  const key = `${chatId}_${name}_${email}`
  console.log('key:', key)
  const session = localStorage.getItem(key)
  console.log('session:', session)
  if (!session) return null

  try {
    const parsedSession = JSON.parse(session)
    return isValidThreadId(parsedSession?.thread_id) ? parsedSession : null
  } catch (e) {
    console.error('Failed to parse session:', e)
    return null
  }
}

  function isTokenExpired(token: string): boolean {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]))
      const expiry = payload.exp
      const now = Math.floor(Date.now() / 1000)
      return now >= expiry
    } catch (e) {
      console.error('Failed to decode token:', e)
      return true
    }
  }

  async function initializeIncognitoSession(
    assistantId: number,
    name: string | null,
    email: string | null,
  ): Promise<SessionResponse> {
    console.log('initializeIncognitoSession assistantId:', assistantId)
    const existingSession = loadSession(assistantId.toString(), name, email)
    console.log('existingSession:', existingSession)

    if (existingSession && !isTokenExpired(existingSession.jwt_token)) {
      console.log('Using existing session:', existingSession)
      return existingSession
    }
    console.log('Creating new session')
    isLoading.value = true
    errorMessage.value = null

    try {
      const response: AxiosResponse<SessionResponse> = await axios.post(
        '/api/v1/initialize_session/incognito',
        {
          name,
          email,
          assistant_id: String(assistantId),
        },
      )

      return response.data
    } catch (error) {
      console.error('Session initialization failed:', error)
      errorMessage.value = 'Failed to initialize session. Please try again.'
      throw error
    } finally {
      isLoading.value = false
    }
  }

  async function initializeSession(
    assistantId: string | number,
    forceNew: boolean = false,
  ): Promise<SessionResponse> {
    isLoading.value = true
    try {
      if (!isAuthenticated.value) {
        /*await loginWithPopup({
          authorizationParams: {
            screen_hint: 'signup',
          },
        })*/
        signup()
      }

      const token: string = await getAccessTokenSilently()
      const url: string = `${backendUrl}/initialize_session${
        forceNew ? '/new' : ''
      }`

      const formData = new FormData()
      const currentUser = user.value
      formData.append('name', currentUser?.name || 'Anonymous')
      formData.append(
        'email',
        currentUser?.email ||
          (currentUser?.nickname ? `${currentUser.nickname}@anonymous.com` : 'anonymous@example.com')
      )
      formData.append('assistant_id', assistantId.toString())
      console.log('user:', currentUser)
      if (currentUser?.nickname) formData.append('nickname', currentUser.nickname)
      if (currentUser?.picture) formData.append('picture', currentUser.picture)

      const headers = {
        accept: 'application/json',
        Authorization: `Bearer ${token}`,
      }

      const response: AxiosResponse<SessionResponse> = await axios.post(
        url,
        formData,
        {
          headers,
          transformRequest: [(data) => data],
        },
      )

      return response.data
    } catch (error) {
      console.error('Session initialization failed:', error)
      errorMessage.value = 'Failed to initialize session. Please try again.'
      throw error
    } finally {
      isLoading.value = false
    }
  }

  return {
    initializeSession,
    initializeIncognitoSession,
    isLoading,
    errorMessage,
    saveSession,
  }
}
